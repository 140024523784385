import React from "react";
import {Route, Switch} from "react-router-dom";
import Home from "./containers/Home";
import Login from "./containers/Login";
import ResetPassword from "./containers/ResetPassword";
import Settings from "./containers/Settings";
import ChangePassword from "./containers/ChangePassword";
import NotFound from "./containers/NotFound";
import AppliedRoute from "./components/AppliedRoute";
import AuthenticatedRoute from "./components/AuthenticatedRoute";
import UnauthenticatedRoute from "./components/UnauthenticatedRoute";

const Routes = ({ childProps }) => (
    <Switch>
            <AppliedRoute path="/" exact component={Home} props={childProps} />
            <UnauthenticatedRoute path="/login" exact component={Login} props={childProps} />
            <UnauthenticatedRoute path="/login/reset" exact component={ResetPassword} props={childProps} />
            <AuthenticatedRoute path="/settings" exact component={Settings} props={childProps} />
            <AuthenticatedRoute path="/settings/password" exact component={ChangePassword} props={childProps} />
            {/* Finally, catch all unmatched routes */}
            <Route component={NotFound} />
    </Switch>
);

export default Routes;

