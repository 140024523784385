import React, {Component, Fragment} from "react";
import { signOut, fetchAuthSession } from 'aws-amplify/auth';
import {Link, withRouter} from "react-router-dom";
import {Nav, Navbar, NavItem} from "react-bootstrap";
import {LinkContainer} from "react-router-bootstrap";
import Routes from "./Routes";
import "./App.css";
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-material.css';

class App extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isAuthenticated: false,
            isAuthenticating: true,
            isProcessing: false,
            isStopping: false,
            isNewPasswordRequired: false,
            currentUser: null,
            groups: "",
            content: "",
            file: null,
            outputArray: [],
            specification: []
        };

        this.events = [
            "load",
            "mousemove",
            "mousedown",
            "click",
            "scroll",
            "keypress"
        ];

        this.warn = this.warn.bind(this);
        this.logout = this.logout.bind(this);
        this.resetTimeout = this.resetTimeout.bind(this);

        let i;
        for (i in this.events) {
            window.addEventListener(this.events[i], this.resetTimeout);
        }
        this.setTimeout();
    }

    clearTimeout() {
        if (this.warnTimeout) {
            clearTimeout(this.warnTimeout);
        }
        if (this.logoutTimeout) {
            clearTimeout(this.logoutTimeout);
        }
    }

    setTimeout() {
        const warnTime = 1439;    // in minutes
        const logoutTime = 1440;  // in minutes
        this.warnTimeout = setTimeout(this.warn, warnTime * 60 * 1000);
        this.logoutTimeout = setTimeout(this.logout, logoutTime * 60 * 1000);
    }

    resetTimeout() {
        this.clearTimeout();
        this.setTimeout();
    }

    warn() {
        // do not provide any warning - just logout
        // alert("You will be logged out automatically in 1 minute.");
    }

    async logout() {
        // Send a logout request to the API
        console.log("Sending a logout request to the API...");
        this.destroy(); // Cleanup
        await this.handleLogout();
    }

    destroy() {
        this.clearTimeout();
        let i;
        for (i in this.events) {
            window.removeEventListener(this.events[i], this.resetTimeout);
        }
    }

    async componentDidMount() {
        try {
            const response = await fetchAuthSession();
            this.userHasAuthenticated(response.userSub);
        } catch (e) {
            if (e !== 'No current user') {
                alert(e);
            }
        }

        this.setState({isAuthenticating: false});
    }

    userHasAuthenticated = authenticated => {
        this.setState({isAuthenticated: authenticated});
    };

    userNeedsNewPassword = newPassword => {
        this.setState({isNewPasswordRequired: newPassword});
    };

    setCurrentUser = currentUser => {
        this.setState({currentUser: currentUser});
    };

    setContent = content => {
        this.setState({content: content});
    };

    setFile = file => {
        this.setState({file: file});
    };

    setIsProcessing = processing => {
        this.setState({isProcessing: processing});
    };

    setIsStopping = stopping => {
        this.setState({isStopping: stopping});
    };

    setOutputArray = array => {
        this.setState({outputArray: array});
    };

    setSpecification = spec => {
        this.setState({specification: spec});
    };

    handleLogout = async event => {
        await signOut();

        this.userHasAuthenticated(false);

        this.props.history.push("/login");
    };

    render() {
        const childProps = {
            isAuthenticated: this.state.isAuthenticated,
            userHasAuthenticated: this.userHasAuthenticated,
            isNewPasswordRequired: this.state.isNewPasswordRequired,
            userNeedsNewPassword: this.userNeedsNewPassword,
            currentUser: this.state.currentUser,
            setCurrentUser: this.setCurrentUser,
            isProcessing: this.state.isProcessing,
            setIsProcessing: this.setIsProcessing,
            isStopping: this.state.isStopping,
            setIsStopping: this.setIsStopping,
            content: this.state.content,
            setContent: this.setContent,
            file: this.state.file,
            setFile: this.setFile,
            outputArray: this.state.outputArray,
            setOutputArray: this.setOutputArray,
            specification: this.state.specification,
            setSpecification: this.setSpecification
        };

        return (
            !this.state.isAuthenticating &&
            <div className="App container">
                <Navbar fluid collapseOnSelect>
                    <Navbar.Header>
                        <Navbar.Brand>
                            <Link to="/">CamAPS: Data Access</Link>
                        </Navbar.Brand>
                        <Navbar.Toggle/>
                    </Navbar.Header>
                    <Navbar.Collapse>
                        <Nav pullRight>
                            {this.state.isAuthenticated && (!this.state.isNewPasswordRequired)
                                ? <Fragment>
                                    <LinkContainer to="/settings">
                                        <NavItem>Settings</NavItem>
                                    </LinkContainer>
                                    <NavItem onClick={this.handleLogout}>Logout</NavItem>
                                </Fragment>
                                : <Fragment>
                                    {/*<LinkContainer to="/signup">*/}
                                    {/*    <NavItem>Signup</NavItem>*/}
                                    {/*</LinkContainer>*/}
                                    <LinkContainer to="/login">
                                        <NavItem>Login</NavItem>
                                    </LinkContainer>
                                </Fragment>
                            }
                        </Nav>
                    </Navbar.Collapse>
                </Navbar>
                <Routes childProps={childProps}/>
            </div>
        );
    }
}

export default withRouter(App);
